import React from "react"
import { graphql } from "gatsby"
import NewsListPage from "../../templates/news-list-page"

const News = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return <NewsListPage edges={edges} />
}

export const NewsEnPageQuery = graphql`
  query NewsEnPage {
    allMarkdownRemark(
      filter: {
        fields: {
          slug: { regex: "//news/[a-z0-9_-]+/?/" }
          langKey: { eq: "en" }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            author {
              name
            }
            date
          }
          excerpt(pruneLength: 350)
          fields {
            slug
          }
        }
      }
    }
  }
`

export default News
